import React from 'react';
import DOMPurify from 'dompurify';

export const FormErrorAlerts = ({errors}: {errors: string[]}) => {
  return (
    <>
      {errors && (
        <div className="alert alert-block alert-danger">
          <ul className="list-unstyled list-spacer-1 mb-0">
            {errors.map((error: string, index: number) => (
              <li key={index} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(error)}} />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
